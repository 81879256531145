const simpleAntonyms = [
  ['Altruistic', 'Selfish'],
  ['Ancient', 'Modern'],
  ['Backward', 'Forward'],
  ['Bad', 'Good'],
  ['Blunt', 'Sharp'],
  ['Bored', 'Amused'],
  ['Boring', 'Interesting'],
  ['Broad', 'Narrow'],
  ['Cheap', 'Expensive'],
  ['Closed', 'Open'],
  ['Cold', 'Hot'],
  ['Cool', 'Warm'],
  ['Cowardly', 'Brave'],
  ['Cruel', 'Kind'],
  ['Dangerous', 'Safe'],
  ['Dark', 'Light'],
  ['Dead', 'Alive'],
  ['Deceitful', 'Sincere'],
  ['Defense', 'Offense'],
  ['Difficult', 'Cooperative'],
  ['Dirty', 'Clean'],
  ['Disagreeable', 'Agreeable'],
  ['Displeasing', 'Pleasing'],
  ['Docile', 'Determined'],
  ['Dry', 'Wet'],
  ['Early', 'Late'],
  ['Easy', 'Difficult'],
  ['Frank', 'Evasive'],
  ['Evil', 'Good'],
  ['Failure', 'Success'],
  ['Fake', 'Real'],
  ['Fearful', 'Fearless'],
  ['Gentle', 'Fierce'],
  ['Guilty', 'Innocent'],
  ['Hesitant', 'Decisive'],
  ['Hostile', 'Harmonious'],
  ['Identical', 'Different'],
  ['Ignorant', 'Knowledgeable'],
  ['Ill', 'Well'],
  ['Inept', 'Talented'],
  ['Instinctive', 'Learned'],
  ['Interior', 'Exterior'],
  ['Laid-back', 'Ambitious'],
  ['Lazy', 'Diligent'],
  ['Left', 'Right'],
  ['Light', 'Heavy'],
  ['Loner', 'Sociable'],
  ['Low', 'High'],
  ['Miserly', 'Generous'],
  ['Natural', 'Artificial'],
  ['Naughty', 'Well-behaved'],
  ['Near', 'Far'],
  ['New', 'Old'],
  ['Normal', 'Strange'],
  ['Passive', 'Aggressive'],
  ['Peaceful', 'Combative'],
  ['Placid', 'Excitable'],
  ['Poor', 'Rich'],
  ['Prey', 'Predator'],
  ['Quiet', 'Noisy'],
  ['Reality', 'Fantasy'],
  ['Sad', 'Happy'],
  ['Sane', 'Crazy'],
  ['Scarce', 'Abundant'],
  ['Sedate', 'Exciting'],
  ['Shallow', 'Deep'],
  ['Short', 'Long'],
  ['Short', 'Tall'],
  ['Shy', 'Outgoing'],
  ['Slow', 'Fast'],
  ['Small', 'Big'],
  ['Smooth', 'Rough'],
  ['Soft', 'Hard'],
  ['Soft', 'Loud'],
  ['Sour', 'Sweet'],
  ['Straightforward', 'Mysterious'],
  ['Stupid', 'Intelligent'],
  ['Talentless', 'Gifted'],
  ['Terrible', 'Wonderful'],
  ['Thin', 'Fat'],
  ['Thrifty', 'Extravagant'],
  ['Tight', 'Loose'],
  ['Ugly', 'Beautiful'],
  ['Unbiased', 'Biased'],
  ['Useless', 'Helpful'],
  ['Weak', 'Strong'],
  ['Witty', 'Humorless'],
  ['Wrong', 'Correct'],
];

const basicAntonyms = [
  ['Bad actor', 'Good actor'],
  ['Basic', 'Hipster'],
  ['Worthless', 'Priceless'],
  ['Nature', 'Nurture'],
  ['Happens slowly', 'Happens suddenly'],
  ['Job', 'Career'],
  ['Round', 'Pointy'],
  ['Proof that God exists', "Proof that God doesn't exist"],
  ['Loved', 'Hated'],
  ['The Light Side of the Force', 'The Dark Side of the Force'],
  ['Stupid', 'Brilliant'],
  ['Artisanal', 'Mass Produced'],
  ['Nobody does it', 'Everybody does it'],
  ['Short lived', 'Long lived'],
  ['Dangerous job', 'Safe job'],
  ['Fantasy', 'Sci-Fi'],
  ['Plain', 'Fancy'],
  ['Has a bad reputation', 'Has a good reputation'],
  ['Ethical to eat', 'Unethical to eat'],
  ['Movie', 'Film'],
  ['Unfashionable', 'Fashionable'],
  ['Freedom fighter', 'Terrorist'],
  ['Bad superpower', 'Good superpower'],
  ['Ineffective', 'Effective'],
  ['Better hot', 'Better cold'],
  ['Square', 'Round'],
  ['Temporary', 'Permanent'],
  ['Looks like a person', "Doesn't look like a person"],
  ['Uncool', 'Cool'],
  ['Worst living person', 'Greatest living person'],
  ['Underrated', 'Overrated'],
  ['Messy food', 'Clean food'],
  ['Unforgivable', 'Forgivable'],
  ['Failure', 'Masterpiece'],
  ['Harmless', 'Harmful'],
  ['Gryffindor', 'Slytherin'],
  ['Unhygienic', 'Hygienic'],
  ['Bad music', 'Good music'],
  ['Useless', 'Useful'],
  ['Movie that Godzilla would ruin', 'Movie that Godzilla would improve'],
  ['Unimportant', 'Important'],
  ['Easy to spell', 'Hard to spell'],
  ['Vice', 'Virtue'],
  ['Underrated musician', 'Overrated musician'],
  ['Unpopular activity', 'Popular activity'],
  ['Divided', 'Whole'],
  ['Unreliable', 'Reliable'],
  ['Easy to kill', 'Hard to kill'],
  ['Unstable', 'Stable'],
  ['Round animal', 'Pointy animal'],
  ['Bad TV show', 'Good TV show'],
  ['Traditionally masculine', 'Traditionally feminine'],
  ['Useless body part', 'Useful body part'],
  ['Fad', 'Classic'],
  ['Weak', 'Strong'],
  ['Disgusting cereal', 'Delicious cereal'],
  ['Bad', 'Good'],
  ['Mildly addictive', 'Highly addictive'],
  ['Useless in an emergency', 'Useful in an emergency'],
  ['For kids', 'For adults'],
  ['Villain', 'Hero'],
  ['Underrated thing to do', 'Overrated thing to do'],
  ['Boring', 'Exciting'],
  ['Smelly in a bad way', 'Smelly in a good way'],
  ['Unpopular', 'Popular'],
  ['Friend', 'Enemy'],
  ['Useless invention', 'Useful invention'],
  ['Liberal', 'Conservative'],
  ['Hot', 'Cold'],
  ['Normal', 'Weird'],
  ['Colorless', 'Colorful'],
  ['Low calorie', 'High calorie'],
  ['Easy subject', 'Hard subject'],
  ['Unknown', 'Famous'],
  ['Rare', 'Common'],
  ['Unsexy emoji', 'Sexy emoji'],
  ['Cheap', 'Expensive'],
  ['Underrated weapon', 'Overrated weapon'],
  ['Feels bad', 'Feels good'],
  ['Inessential', 'Essential'],
  ['Dirty', 'Clean'],
  ['Requires luck', 'Requires skill'],
  ['Flavorless', 'Flavorful'],
  ['Boring topic', 'Fascinating topic'],
  ['Casual', 'Formal'],
  ['Underpaid', 'Overpaid'],
  ['Dry', 'Wet'],
  ['Underrated skill', 'Overrated skill'],
  ['Forbidden', 'Encouraged'],
  ['Sad song', 'Happy song'],
  ['Fragile', 'Durable'],
  ['Geek', 'Dork'],
  ['Good', 'Evil'],
  ['Worst day of the year', 'Best day of the year'],
  ['Bad habit', 'Good habit'],
  ['Cat person', 'Dog person'],
  ['Wise', 'Intelligent'],
  ['Hard to do', 'Easy to do'],
  ['Mental activity', 'Physical activity'],
  ['Uncontroversial topic', 'Controversial topic'],
  ['Guilty pleasure', 'Openly love'],
  ['Untalented', 'Talented'],
  ['Hard to find', 'Easy to find'],
  ['Ugly Man', 'Beautiful Man'],
  ['Hard to remember', 'Easy to remember'],
  ['Lowbrow', 'Highbrow'],
  ['Unhealthy', 'Healthy'],
  ['Bad man', 'Good man'],
  ['Historically important', 'Historically irrelevant'],
  ['Hairless', 'Hairy'],
  ['Inflexible', 'Flexible'],
  ['Normal pet', 'Exotic pet'],
  ['Introvert', 'Extrovert'],
  ['Book was better', 'Movie was better'],
  ['Bad movie', 'Good movie'],
  ['Ugly', 'Beautiful'],
  ['Mature person', 'Immature person'],
  ['Underrated thing to own', 'Overrated thing to own'],
  ['Ordinary', 'Extraordinary'],
  ['Hard to pronounce', 'Easy to pronounce'],
  ['Poorly made', 'Well made'],
  ['Not a sandwich', 'A sandwich'],
  ['Dangerous', 'Safe'],
  ['Culturally significant', 'Culturally insignificant'],
  ['Optional', 'Mandatory'],
  ['Underrated letter of the alphabet', 'Overrated letter of the alphabet'],
  ['Low quality', 'High quality'],
  ['Unsexy animal', 'Sexy animal'],
  ['Quiet place', 'Loud place'],
  ['Comedy', 'Drama'],
  ['Need', 'Want'],
  ['Dry food', 'Wet food'],
  ['Replaceable', 'Irreplaceable'],
  ['Worst athlete of all time', 'Greatest athlete of all time'],
  ['Unethical', 'Ethical'],
  ['Boring hobby', 'Interesting hobby'],
  ['Bad pizza topping', 'Good pizza topping'],
  ['Dystopia', 'Utopia'],
  ['Rough', 'Smooth'],
  ['Bad for you', 'Good for you'],
  ['Peaceful', 'Warlike'],
  ['Underrated Movie', 'Overrated movie'],
  ['Tastes bad', 'Tastes good'],
  ['Sport', 'Game'],
  ['Sad movie', 'Happy movie'],
  ['Waste of time', 'Good use of time'],
  ['Least evil company', 'Most evil company'],
  ['Snack', 'Meal'],
  ['Unbelievable', 'Believable'],
  ['Trashy', 'Classy'],
  ['Smells bad', 'Smells good'],
  ['Star Wars', 'Star Trek'],
  ['Scary animal', 'Nice animal'],
  ['Mainstream', 'Niche'],
  ['Dark', 'Light'],
  ['Underrated actor', 'Overrated actor'],
  ['Difficult to use', 'Easy to use'],
  ['Tired', 'Wired'],
  ['80s', '90s'],
  ['Bad person', 'Good person'],
  ['Sustenance', 'Haute cuisine'],
  ['Soft', 'Hard'],
  ['Normal thing to own', 'Weird thing to own'],
  ['Straight', 'Curvy'],
  ['Role model', 'Bad Influence'],
  ['Useless major', 'Useful major'],
  ['Mean person', 'Nice person'],
  ['Action movie', 'Adventure movie'],
];

const advancedAntonyms = [
  ['Short', 'Long'],
  ['Worst year in history', 'Best year in history'],
  ['Famous', 'Infamous'],
  ['Least powerful god', 'Most powerful god'],
  ['Unsexy color', 'Sexy color'],
  ['Benefits you', 'Benefits everyone'],
  ['Bad president', 'Good president'],
  ['Weird', 'Strange'],
  ['Derivative', 'Original'],
  ['Etiquette', 'Manners'],
  ['The worst', 'The best'],
  ['Small number', 'Large number'],
  ['Not enough', 'Too much'],
  ['Hard to sit on', 'Easy to sit on'],
  ['Talent', 'Skill'],
  ['Worst era to time travel', 'Best era to time travel'],
  ['Not huggable', 'Huggable'],
  ['Heterogeneous', 'Homogeneous'],
  ['Out of control', 'In control'],
  ['Popular', 'Elitist'],
  ['Non-partisan', 'Partisan'],
  ['Dog name', 'Cat name'],
  ['Little known fact', 'Well known fact'],
  ['Socialist', 'Capitalist'],
  ['Bad candy', 'Good candy'],
  ['Traditional', 'Radical'],
  ['Bad mouthfeel', 'Good mouthfeel'],
  ['Illegal', 'Legal'],
  ['Never on time', 'Always on time'],
  ["Won't live to 100", 'Will live to 100'],
  ['Bad Disney character', 'Good Disney character'],
  ['Similar', 'Identical'],
  ['Limp', 'Firm'],
  ['Funny topic', 'Serious topic'],
  ['Unscented', 'Scented'],
  ['Horizontal', 'Vertical'],
  ['Small', 'Tiny'],
  ['Ugly word', 'Beautiful word'],
  ['Tick', 'Tock'],
  ['Bad advice', 'Good advice'],
  ['Illegal', 'Prohibited'],
  ['Not art', 'Art'],
  ['Gossip', 'News'],
  ['Guilty pleasure', 'Actually just bad'],
  ['Old fashioned', 'Avant garde'],
  ['True', 'False'],
  ['Normal greeting', 'Weird greeting'],
  ['Dictatorship', 'Democracy'],
  ['Powerless', 'Powerful'],
  ['Vapes', "Doesn't Vape"],
  ['Boring person', 'Fun person'],
  ['Underrated book', 'Overrated book'],
  ['Deep thought', 'Shallow thought'],
  ['Bad school', 'Good school'],
  ['Conventional wisdom', 'Fringe belief'],
  ['Worst chore', 'Best chore'],
  ['Endangered species', 'Overpopulated species'],
  ['Blue', 'Green'],
  ['Fruit', 'Vegetable'],
  ['Science', 'Pseudoscience'],
  ['Small talk', 'Heavy topic'],
  ['Bad investment', 'Good investment'],
  ['Stationary', 'Mobile'],
  ['Local issue', 'Global issue'],
  ['Thrilling', 'Terrifying'],
  ['Nerd', 'Jock'],
  ['Expected', 'Unexpected'],
  ['Person you could beat up', "Person who'd beat you up"],
  ['Limited', 'Infinite'],
  ['Casual event', 'Formal event'],
  ['Unreasonable phobia', 'Reasonable phobia'],
  ['Underrated game', 'Overrated game'],
  ['Religious', 'Sacrilegious'],
  ['Mild', 'Spicy'],
  ['Genuine person', 'Phony person'],
  ['Unnatural', 'Natural'],
  ['Secret', 'Public Knowledge'],
  ['Too small', 'Too big'],
  ['Art', 'Commerce'],
  ['One hit wonder', 'Pop icon'],
  ['Unsexy Pokémon', 'Sexy Pokémon'],
  ['Quiet', 'Loud'],
  ['Inclusive', 'Exclusive'],
  ['Bad dog (breed)', 'Good dog (breed)'],
];

/* Randomize array in-place using Durstenfeld shuffle algorithm */
function shuffleArray<T>(array: Array<T>) {
  for (var i = array.length - 1; i > 0; i--) {
    var j = Math.floor(Math.random() * (i + 1));
    var temp = array[i];
    array[i] = array[j];
    array[j] = temp;
  }
}

const antonyms = simpleAntonyms.concat(basicAntonyms).concat(advancedAntonyms);
shuffleArray(antonyms);

export default antonyms;
